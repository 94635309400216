<template>
    <div>
        <h1>主页</h1>
    </div>
</template>
<script>
    export default {
        name: 'HelloWorld',
        data() {
            return {
                msg: 'AT',
            }
        },
        methods: {
            test(){
                console.log('xxxx',process.env.BASE_URL)
            },
            // 测试按钮
            testxxx() {
                let key = 'test';
                let data = [1, 2, 3]
                console.log('爆粗的数据', data)
                this.Tool.set_l_cache(key, data)

            },
            gettest() {
                let key = 'test';
                let data = this.Tool.get_l_cache(key)
                console.log('获取的数据', data)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
